import React, { useState } from "react"
import { Button, Col, FormGroup, Input, Progress, Row } from "reactstrap"
import * as styles from "./index.module.less"
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik"
import * as Yup from "yup"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import OnlyAuthorized from "../../../auth/OnlyAuthorized"
import { useQuery } from "react-query"
import { useAuth } from "../../../auth/useAuth"
import AddCategory from "./_AddCategory"

const Categories = () => {
  const { makeRequest, isAuthenticated } = useAuth() || {}

  const [edit, setEdit] = useState({})

  const [deleting, setDeleting] = useState(false)

  const {
    isLoading,
    error,
    data = [],
    refetch,
  } = useQuery({
    queryKey: ["categoryData"],
    queryFn: () =>
      makeRequest("categories/list").then((res) => res?.categories || []),
    enabled: isAuthenticated,
  })

  const deleteCategory = async (id) => {
    setDeleting(true)
    await makeRequest("categories/delete", { id })
    await refetch()
    setDeleting(false)
  }

  return (
    <>
      <SEO title="Categories" />
      <Layout title={"Categories"}>
        <OnlyAuthorized permission={"manage:space-providers"}>
          <div className="row">
            <div className="col-md-6">
              <Formik
                initialValues={{
                  id: "",
                  name: "",
                  slug: "",
                  icon_url: "",
                  for_offices: false,
                  for_desks: false,
                  for_meeting_rooms: false,
                  order: 1,
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .min(2, "Too Short!")
                    .max(50, "Too Long!")
                    .required("Required"),
                  slug: Yup.string()
                    .min(2, "Too Short!")
                    .max(50, "Too Long!")
                    .required("Required"),
                  icon_url: Yup.string().required("Required"),
                })}
                onSubmit={async (values, { resetForm }) => {
                  await makeRequest("categories/save", values)
                  await refetch()
                  resetForm()
                  setEdit({})
                }}
              >
                {(props) => (
                  <AddCategory {...props} edit={edit} setEdit={setEdit} />
                )}
              </Formik>
            </div>

            <div className="col-md-6">
              <table className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Slug</th>
                    <th>Icon</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((cat) => {
                    const { id, name, slug, icon_url, order } = cat
                    return (
                      <tr key={id}>
                        <td>
                          ({order}) {name}
                        </td>
                        <td>{slug}</td>
                        <td>
                          <img src={icon_url} alt="" height={36} />
                        </td>
                        <td>
                          <button
                            className="btn btn-link text-danger p-0 btn-sm"
                            style={{
                              minWidth: "20px",
                            }}
                            onClick={() => deleteCategory(id)}
                            disabled={deleting}
                          >
                            Delete
                          </button>{" "}
                          |{" "}
                          <button
                            className="btn btn-link text-warning p-0 btn-sm"
                            style={{
                              minWidth: "20px",
                            }}
                            onClick={() => setEdit(cat)}
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </OnlyAuthorized>
      </Layout>
    </>
  )
}

export default Categories
