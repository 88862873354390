import React, { useEffect, useState } from "react"
import { Field, Form, Formik, useFormikContext } from "formik"
import Dropzone from "react-dropzone"
import { Progress } from "reactstrap"
import { v4 as uuid } from "uuid"
import createImage from "../../../templates/EditSpace/TabContent/OnDemandWorkspace/Sections/MeetingRooms/createImage"
import { useAuth } from "../../../auth/useAuth"
import { PLAN_SVG } from "../../../templates/EditSpace/Dropzones/constants"
import RadioBox from "../../../templates/EditSpace/fields/RadioSwitch"

const AddCategory = ({
  isSubmitting,
  values,
  dirty,
  isValid,
  resetForm,
  edit,
  setEdit = () => null,
  setFieldValue = () => null,
}) => {
  const { makeRequest } = useAuth() || {}

  const [imageProgress, setImageProgress] = useState(false)

  const formik = useFormikContext()

  useEffect(() => {
    if (edit?.id) {
      formik?.setFieldValue("name", edit?.name)
      formik?.setFieldValue("id", edit?.id)
      formik?.setFieldValue("icon_url", edit?.icon_url)
      formik?.setFieldValue("slug", edit?.slug)
      formik?.setFieldValue("order", edit?.order)
      formik?.setFieldValue("for_desks", edit?.for_desks)
      formik?.setFieldValue("for_offices", edit?.for_offices)
      formik?.setFieldValue("for_meeting_rooms", edit?.for_meeting_rooms)
    } else {
      resetForm()
    }
  }, [edit?.id])

  const onDrop = (files) => {
    setImageProgress(true)
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader()
        const file = files[i]
        reader.readAsDataURL(file)
        reader.onload = async (e) => {
          const response = await makeRequest(
            "upload/single-image/get-signed-url",
            {
              key: `icons/${uuid()}.svg`,
              type: "image/svg+xml",
            }
          )

          let binary = atob(e.target.result.split(",")[1])
          let array = []
          // for each letter in the encrypted version, get binary value and push it to array
          for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i))
          }
          // use the array to construct a binary object (blob)
          let blobData = new Blob([new Uint8Array(array)], {
            type: "image/svg+xml",
          })

          await fetch(response.uploadURL, {
            method: "PUT",
            body: blobData,
            mode: "cors",
          })

          setImageProgress(false)

          formik?.setFieldValue("icon_url", response.Url)
        }
      }
    }
  }

  return (
    <>
      <Form>
        <div className="mb-3">
          <label htmlFor="">Name</label>
          <Field type="text" name="name" className={"form-control"} />
        </div>
        <div className="mb-3">
          <label htmlFor="">Slug</label>
          <Field type="text" name="slug" className={"form-control"} />
          <small>Unique id. Only lowercase letters and hyphens.</small>
        </div>

        <div className="mb-3">
          <label htmlFor="">Order</label>
          <Field type="number" name="order" className={"form-control"} />
        </div>

        <div className="mb-3">
          <RadioBox
            label="Available for Offices?"
            onChange={(value) => setFieldValue("for_offices", value)}
            name="for_offices"
            value={values.for_offices}
          />
          <RadioBox
            label="Available for Desks?"
            onChange={(value) => setFieldValue("for_desks", value)}
            name="for_desks"
            value={values.for_desks}
          />
          <RadioBox
            label="Available for Meeting Rooms?"
            onChange={(value) => setFieldValue("for_meeting_rooms", value)}
            name="for_meeting_rooms"
            value={values.for_meeting_rooms}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="">Icon</label>
          <div className="row">
            <div className="col-6">
              <Dropzone
                disabled={imageProgress}
                multiple={false}
                accept={"image/svg+xml"}
                // to pass the category with the image
                onDrop={(files) => onDrop(files)}
                // onFileDialogCancel={this.onCancel.bind(this)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className={`dropzone-custom-wrapper `}
                  >
                    <input {...getInputProps()} />
                    <div className={`dropzone-content`}>
                      {imageProgress ? (
                        <Progress
                          animated
                          bar
                          value="100"
                          style={{ height: "8px" }}
                        />
                      ) : (
                        <p className="mb-0">
                          Drag / Click to select
                          <br />
                          (Only .svg)
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
            <div className="col-6">
              {values?.icon_url && (
                <img
                  src={values?.icon_url}
                  alt={values?.name || ""}
                  height={64}
                />
              )}
            </div>
          </div>
        </div>

        <button
          type="submit"
          disabled={isSubmitting || !dirty || !isValid}
          className={"btn btn-primary"}
        >
          Save
        </button>
        <button
          className="btn btn-link text-warning"
          onClick={() => {
            setEdit({})
          }}
        >
          Reset
        </button>
      </Form>
    </>
  )
}

export default AddCategory
