export const defaultImageFormat = {
  ext: "jpg",
  format: "image/jpeg",
}

const createImage = async (file = null, options = {}) => {
  const {
    resize = true,
    dWidth = 1920,
    dHeight = 1080,
    imageFormat = defaultImageFormat,
  } = options
  let img = document.createElement("img")

  img.src = await new Promise((resolve) => {
    let reader = new FileReader()
    reader.onload = (e) => resolve(e.target.result)
    reader.readAsDataURL(file)
  })
  await new Promise((resolve) => (img.onload = resolve))

  let canvas = document.createElement("canvas")

  let ctx = canvas.getContext("2d")
  ctx.drawImage(img, 0, 0)

  if (!resize) {
    return canvas.toDataURL(imageFormat.format)
  }

  const imageWidth = img.width
  const imageHeight = img.height

  const sWidth = img.width
  const sHeight = img.height

  let resizeRatio = 1

  const widthRatio = dWidth / sWidth
  const heightRatio = dHeight / sHeight

  if (sHeight * widthRatio >= dHeight) {
    resizeRatio = widthRatio
  } else {
    resizeRatio = heightRatio
  }

  canvas.width = dWidth
  canvas.height = dHeight

  const resizedImageWidth = sWidth * resizeRatio
  const resizedImageHeight = sHeight * resizeRatio

  ctx.drawImage(
    img,
    0,
    0,
    imageWidth,
    imageHeight,
    Math.ceil((dWidth - resizedImageWidth) / 2),
    Math.ceil((dHeight - resizedImageHeight) / 2),
    resizedImageWidth,
    resizedImageHeight
  )

  return canvas.toDataURL(imageFormat.format)
}

export default createImage
