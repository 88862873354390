const PLAN_BACKGROUND = "space-plan-background"
const PLAN_SVG = "space-plan-svg"
const HERO_IMAGE = "hero-image"
const LOGO = "logo"
const COMMON_IMAGES = "common-images"
const BUILDING_IMAGES = "building-images"
const USER_IMAGE = "user-image"

const ALLOWED_SECTIONS = [
  PLAN_BACKGROUND,
  PLAN_SVG,
  HERO_IMAGE,
  LOGO,
  COMMON_IMAGES,
  BUILDING_IMAGES,
  USER_IMAGE,
]

module.exports = {
  PLAN_BACKGROUND,
  USER_IMAGE,
  PLAN_SVG,
  HERO_IMAGE,
  LOGO,
  COMMON_IMAGES,
  BUILDING_IMAGES,
  ALLOWED_SECTIONS,
}
